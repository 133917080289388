import moment from "moment";
import { mapActions } from "vuex"
import { ByteBrew } from "bytebrew-web-sdk";

export default {
  data() {
    return {
        moment
    }
  },
  methods: {
    ...mapActions(["feedHourlyStat"]),
    feedGTag(e, v, c, l) {
        if(!e) {
            console.log('Event name must be required.')
            return false
        }

        var event = {}

        if(v) {
            event['value'] = v
        }

        if(c) {
            event['event_category'] = c
        }

        if(l) {
            event['event_label'] = l
        }

        if(this.$store.state.authenticated) {
            event['user_id'] = this.$store.state.player.id
        }

        this.$gtag.event(e, event)
    },
    feedStat(e, v, p) {
        if(this.$store.state.authenticated) {
            this.feedHourlyStat({
                recordedAt: moment().format('YYYY-MM-DD'),
                hour: moment().format('H'),
                event: e,
                value: v ? v : 1,
                param: p ? p : null
            })
        }
    },
    feedByteBrewStat(e, v, c, l) {
        var event = {}

        if(v) {
            event['value'] = v
        }

        if(c) {
            event['event_category'] = c
        }

        if(l) {
            event['event_label'] = l
        }

        ByteBrew.newCustomEvent(e, event)
    },
    feedAllEvent(e, v, c, l, p) {
        this.feedGTag(e, v, c, l)
        this.feedStat(e, v, p)
        this.feedByteBrewStat(e, v, c, l)
    },
    feedMissingOneSignalTags(collection) {
          var tags = this._.cloneDeep(collection)
          let tagsKeys = Object.keys(tags)

          this._.forEach(this.defaultOneSignalTags, (v, k) => {
              if (!tagsKeys.includes(k)) {
                  tags[k] = v
              }
          })

          this.$OneSignal.sendTags(tags)
    }
  }
}